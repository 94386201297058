import React from "react";
import Layout from "../components/layout/Layout";
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default ({ data, location }) => {
    return (
        <Layout title="About us" transparentNavigation={true} location={location}>
            <main id="content" style={{ marginTop: "-80px" }}>
                <section className="hero is-fullheight">
                    <GatsbyImage
                        image={getImage(data.bg)}
                        style={{ width: "100%", position: "absolute", top: "0", left: "0px", height: "100vh" }}
                        alt="" />
                    <div className="hero-body">
                        <div className="container content has-text-centered">
                            <h1 className="title font-variable size-100 is-white">
                                not at all
                            </h1>
                            <h2 className="subtitle font-bold size-100 is-transparent text-stroke-white">
                                strangers
                            </h2>
                            <div className="columns">
                                <div className="column is-half is-offset-one-quarter">
                                    <p className="is-white font-regular">
                                        Read more about where we come from, how we got here, and who are the people behind Frends.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="container">
                        <div className="columns has-text-centered is-mobile is-multiline is-reversed-mobile is-vcentered is-centered">
                            <div className="column is-12-mobile">
                                <h2 className="font-bold size-100 is-transparent text-stroke-black">the name</h2>
                                <p className="size-20">
                                    The name "Frends" is actually an acronym that comes from the initial integration use case Frends was used in; which was using dial-up modems to connect to every gas station in Finland and exchange data between the stations and a central IBM Mainframe. This resulted in the acronym Front End Dialing System or Frends.
                                </p>
                            </div>
                            <div className="column is-12-mobile">
                                <GatsbyImage image={getImage(data.greensquare)} alt="" />
                            </div>
                        </div>
                        <div className="columns has-text-centered is-vcentered is-centered">
                            <div className="column is-6">
                                <GatsbyImage image={getImage(data.bluecircle)} alt="" />
                            </div>
                            <div className="column is-6">
                                <h2 className="font-bold size-100 is-transparent text-stroke-black">the team</h2>
                                <p className="size-20">
                                    Currently, the Frends team, aka our Frendszone, consists of over 200 employees spread across 16 countries. We are on a growth journey and actively looking for future Frendszies to join our team! Are you interested? Check our <a href="https://careers.frends.com/" target="_blank" rel="noreferrer noopener">Career portal</a>!
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section is-hidden-mobile is-blue-bg">
                    <div className="container">
                        <h2 className="ingress">key personnel</h2>
                        <div className="columns is-multiline">
                            {data.allContentfulPersonnel.edges.map((person, i) => (
                                <div className="column is-6" key={i}>
                                    <div className="columns is-vcentered">
                                        <div className="column is-5">
                                            <GatsbyImage
                                                image={getImage(person.node.portrait)}
                                                className="black-and-white"
                                                alt={person.node.name} />
                                        </div>
                                        <div className="column is-7 content padding-x-30">
                                            <h3 className="size-28 no-margin">{person.node.name}</h3>
                                            <p className="is-blue size-20">{person.node.title}</p>
                                            <a href={`mailto:${person.node.email}`}><span className="size-16">{person.node.email}</span></a><br />
                                            <a href={`tel:${person.node.telephone}`}><span className="size-16">{person.node.telephone}</span></a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <section className="section is-hidden-tablet is-blue-bg" >
                    <div className="container">
                        <h2 className="ingress has-text-centered">key personnel</h2>
                        <div className="columns is-mobile is-vcentered is-multiline">
                            {data.allContentfulPersonnel.edges.map((person, i) => (
                                <React.Fragment key={i}>
                                    <div className="column is-4">
                                        <GatsbyImage
                                            image={getImage(person.node.portrait)}
                                            className="black-and-white"
                                            alt={person.node.name} />
                                    </div>
                                    <div className="column is-8 content">
                                        <h3 className="size-28 no-margin">{person.node.name}</h3>
                                        <span className="is-blue size-16">{person.node.title}</span><br />
                                        <a href={`mailto:${person.node.email}`}><span className="size-16">{person.node.email}</span></a><br />
                                        <a href={`mailto:${person.node.telephone}`}><span className="size-16">{person.node.telephone}</span></a>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    );
}


export const query = graphql`query Personnel {
  allContentfulPersonnel(
    sort: {fields: order}
    filter: {node_locale: {eq: "en-US"}, order: {gt: 0}}
  ) {
    edges {
      node {
        email
        name
        portrait {
            gatsbyImageData(width: 380)
        }
        telephone
        title
      }
    }
  }
  bg: file(relativePath: {eq: "images/purple_bg.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  greensquare: file(relativePath: {eq: "images/green_animated_square.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 400, height: 400, quality: 100, layout: CONSTRAINED)
    }
  }
  bluecircle: file(relativePath: {eq: "images/blue_animated_circle.png"}) {
    childImageSharp {
      gatsbyImageData(width: 400, height: 400, quality: 100, layout: CONSTRAINED)
    }
  }
}
`
